import React, { useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import axios from "../../services/axios";
import { loginUser } from '../../services/auth';
import { setUser } from '../../store/actions/User';
import { loginSuccess } from '../../store/actions/Auth';
import { validateEmail } from '../../utilis/validation';
// Material
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import bannerImg from '../../images/happiness.svg';
import ivLogoImg from '../../images/ivLogo.svg';
import windowDimensions from '../../utilis/windowDimension';
import './Login.scss';

import ReCAPTCHA from "react-google-recaptcha";
import cogoToast from 'cogo-toast';

const Login = (props) => {
  const {width} = windowDimensions();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [inputErrorState, setInputErrorState] = useState(false);
  const [inputErrorText, setInputErrorText] = useState('');

  const recaptcha = useRef();
	const recaptchaSiteKey = '6LfPXEUpAAAAALfPFekzaSPw0ITh1sBj7xCW3e5r';

  useEffect(()=> {
    localStorage.setItem('firstLogin', true);
  },[]);

  useEffect(() => {
    setError('')
  }, [email, password]);

  const validateInput = () => {
    if(!validateEmail(email)){
      setErrorEmail(true)
      if(password === ''){
        setErrorPassword(true);
        return false
      }
      return false;
    }
    if(password === ''){
      setErrorPassword(true);
      return false;
    }
    return true
  };
  const handleClickShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const signIn = async (e) => {
    const captchaValue = recaptcha.current.getValue();

		if (!captchaValue) {
             alert("Please verify the reCAPTCHA!");
	         return;
        } 

    e.preventDefault();
    if(!validateInput()) return;
    const resp = await loginUser({email, password});
    const user = resp?.user
    const access_token = resp?.access_token
    const refresh_token = resp?.refresh_token
    // window.zipy.identify(`USER-${user?.id.toString()}`);
    try {
      setLoading(true);
      if(access_token){
          axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
          dispatch(setUser(user));
          dispatch(loginSuccess(access_token, refresh_token));
          setLoading(false);
          setError('');
      } else{
        setInputErrorState(true);
        setInputErrorText("Failed to login")
      }
    } catch (err) {
      setLoading(false);
      setError('Invalid Credentials');  
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={width > 786 ? "row-reverse" : "column"}
      justifyContent={width > 786 ? "space-around" : "center"}
      alignItems="center"
      className="login-parent-wrapper"
    >
      <Box display="flex" justifyContent="center" className="width-50">
        <img
          className={width > 786 ? "banner-img-full" : "banner-img"}
          src={bannerImg} alt="banner pic"
        />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className="width-50"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography className="sign-in-text">Sign In</Typography>
          <Typography className="access-dashboard-text">
            Free access to our dashboard.
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" alignItems='center' flexDirection="column">
          <TextField
            error={inputErrorState}
            helperText={inputErrorText}
            onChange={(e) => {
              setErrorEmail(false);
              setEmail(e.target.value);
            }}
            className={width > 786 ? "input-full mb-24" : "input-mobile mb-24"}
            label="Email Address"
            variant="outlined"
          />

           <FormControl variant="outlined" className='mb-24'>
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            error={inputErrorState}
            className={width > 786 ? "input-full" : "input-mobile"}
            id="outlined-adornment-password"
            type={isPasswordVisible ? 'text' : 'password'}
            value={password}
            onChange={(e) => {
              setErrorPassword(false);
              setPassword(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {password ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        <ReCAPTCHA ref={recaptcha}  sitekey={recaptchaSiteKey} />

          <Button
            className="sign-in-btn"
            variant="contained"
            color="primary"
            onClick={(e) => signIn(e)}
          >
            Sign in
          </Button>
        </Box>

        <Typography className="forgot-password">
          
          <Link
            to="/forgot-password"
            className="form-text small btn-link pointer"
          >
            Forgot Password?
          </Link>
        </Typography>

        <Box
          className="bottom-logo"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img className='iv-logo' src={ivLogoImg} alt="logo img" />
          <Typography className='company-logo-text'>InterviewVector</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
